import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import debounce from "lodash.debounce";
import Select from "react-select";
import moment from "moment";
import { toast } from "react-toastify";
import md5 from "crypto-js/md5";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
} from "recharts";
import { writeFile, utils } from "xlsx";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import Tooltip from "@mui/material/Tooltip";

import {
  getMemberships,
  getMembershipStats,
  getUaMemberships,
  addMemberShipOrder,
} from "../../../../api";
import _ from "../../../../i18n";
import { SELECT_STYLES, TTP_OFFFCOURSE_URL } from "../../../../config";

import Tabs from "../../../common/Tabs";
import Pagination from "../../../common/Pagination";
import Loader from "../../../common/Loader";
import Button from "../../../common/Button";
import IconUsers from "../../../common/icons/IconUsers";

import CompareList from "./CompareList";
import OeccbbHotels from "./OeccbbHotels";
import MemberOrderModal from "./MemberOrderModal";
import styles from "./ManageMembership.module.scss";

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? "#e6e6e6" : "#fff",
    boxShadow: "none",
    border: state.isFocused ? "1px solid #2495E1" : "1px solid #CED4DB",
    "&:hover": {
      borderColor: state.isFocused ? "#18A0FB" : "#DFE2E6",
    },
    padding: 0,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#6D7F92",
    fontWeight: 400,
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: "0",
    paddingBottom: "0",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    overflow: "hidden",
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
    fontSize: "12px",
    lineHeight: "14px",
    color: state.isSelected ? "#FFFFFF" : "#6D7F92",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#29394D",
  }),
};

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884d8"];

const statusOptions = [
  { label: _("pending"), value: "PENDING" },
  { label: _("paid"), value: "PAID" },
];

const sourceOptions = [
  { label: "UA", value: "UA" },
  { label: "OECCBB", value: "OECCBB" },
];

const currentYear =
  moment().month() == 11 ? moment().year() + 1 : moment().year();

const yearsOptions = [{ value: currentYear, label: currentYear }];

for (let i = currentYear - 1; i >= 2020; i--) {
  yearsOptions.push({ value: i, label: i });
}

const ManageMembership = (props) => {
  const queryClient = useQueryClient();
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const language = useSelector((state) => state.params.language);
  const [status, setStatus] = useState(statusOptions[1]);
  const [source, setSource] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [memberships, setMemberShips] = useState(null);
  const [formulas, setFormulas] = useState(null);
  const [formulaFilter, setFormulaFilter] = useState(null);
  const [formulaOptions, setFormulaOptions] = useState([]);
  const [year, setYear] = useState(yearsOptions[0]);
  const [currentFetchedYear, setCurrentFetchedYear] = useState(null);
  const [sort, setSort] = useState({
    property: "createdAt",
    dir: "desc",
  });
  const [dataChart, setDataChart] = useState([]);
  const [previousYear, setPreviousYear] = useState([]);
  const [excludeYear, setExcludeYear] = useState(yearsOptions[0]);
  const [search, setSearch] = useState("");
  const [currentFilterTab, setCurrentFilterTab] = useState(0);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [currentMemberOrder, setCurrentMemberOrder] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [bdcFilter, setBdcFilter] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [total, setTotal] = useState(0);

  const LIMIT = 20;

  let { isLoading, data } = useQuery(
    [
      "listMemberships",
      token,
      year,
      sort,
      status,
      source,
      currentPage,
      formulaFilter,
      excludeYear,
      previousYear,
      search,
      bdcFilter,
    ],
    async () => {
      if (token) {
        if (!memberships) {
          try {
            const response = await getUaMemberships(token);
            let membershipTab = {},
              formulasTab = {},
              formulaOptionsTab = [];
            response.data.data.forEach((item) => {
              membershipTab[item.id] = {
                id: item.id,
                name: item.nameFr,
                hasSlider: item.hasSlider,
                type: item.type,
              };
              item.uaMemberShipFormulas.forEach((formula) => {
                formulasTab[formula.id] = {
                  id: formula.id,
                  name: formula.nameFr,
                  annualPrice: formula.annualPrice,
                };
                formulaOptionsTab.push({
                  value: formula.id,
                  label: formula.nameFr,
                  membership: item.id,
                  annualPrice: formula.annualPrice,
                });
              });
            });
            formulaOptionsTab.push({
              value: "ASSOCIATE",
              label: "Associé",
            });
            setFormulaOptions(formulaOptionsTab);
            setMemberShips(membershipTab);
            setFormulas(formulasTab);
          } catch (e) {}
        }
        if (currentFetchedYear !== year) {
          try {
            const resp = await getMembershipStats({
              token,
              year: year.value,
              status: status.value,
              source: source ? source.value : null,
            });
            setCurrentFetchedYear(year.value);
            setTotal(resp.data.data.total);
            setDataChart(
              resp.data.data.stats.map((i) => {
                return {
                  name: i.formula,
                  value: i.count,
                };
              })
            );
          } catch (e) {
            setTotal(0);
            setDataChart([]);
          }
        }
        try {
          const formulaIds = formulaFilter
            ? formulaFilter.map((i) => i.value)
            : [];
          let exYear =
            currentFilterTab === 1
              ? excludeYear
                ? excludeYear.value
                : null
              : null;
          let prevYear =
            currentFilterTab === 1
              ? previousYear.length > 0
                ? previousYear.map((i) => i.value)
                : null
              : null;
          const response = await getMemberships({
            token,
            status: status.value,
            page: currentPage,
            limit: LIMIT,
            sort,
            year: year.value,
            excludeYear: exYear,
            previousYear: prevYear,
            formulaIds,
            source: source ? source.value : null,
            search,
            bdcFilter: bdcFilter ? bdcFilter.value : null,
          });
          return response.data;
        } catch (e) {
          return [];
        }
      }
    },
    { cacheTime: 0 }
  );

  useEffect(() => {
    if (props.refreshList) {
      queryClient.invalidateQueries("listMemberships");
      props.setRefreshList(false);
    }
  }, [props.refreshList]);

  const handleTabFilterChange = (e) => {
    setCurrentFilterTab(e);
    if (e == 1) {
      setPreviousYear([yearsOptions[1]]);
      setExcludeYear(yearsOptions[0]);
      setStatus(statusOptions[1]);
      setSource(null);
      setFormulaFilter(null);
    } else {
      setExcludeYear(null);
    }
  };

  const handleSearchChange = (e) => {
    debouncedLoadResults(e.target.value);
  };

  const debouncedLoadResults = debounce((search) => {
    setSearch(search);
    setCurrentPage(1);
  }, 1000);

  const handleStatusChange = (e) => {
    setCurrentPage(1);
    setStatus(e);
  };

  const handleSendOrder = (item) => {
    setCurrentMemberOrder(item);
    setShowBillingModal(true);
  };

  const handleMemberShipOrder = (data) => {
    setIsSaving(true);
    let memberShip = JSON.stringify(data.data);
    addMemberShipOrder({
      token,
      language,
      memberShip,
      uen: data.uen,
      organization: data.organization,
      address: data.address,
      postalCode: data.postalCode,
      city: data.city,
      subjectToVAT: data.subjectToVAT,
      user: data.user.id,
      orderFromOECCBB: true,
    })
      .then((response) => {
        if (response.data.data) {
          toast.success(_("successfully_sent"));
          setIsSaving(false);
          queryClient.invalidateQueries("listMemberships");
        }
      })
      .catch((e) => {
        setIsSaving(false);
        toast.error(_("error_add_order"));
      });
  };

  const renderChart = () => {
    if (dataChart.length === 0) {
      return null;
    }
    return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={150} height={150}>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={dataChart}
            cx="50%"
            cy="50%"
            outerRadius={45}
            fill="#8884d8"
            label
          >
            {dataChart.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <ChartTooltip />
        </PieChart>
      </ResponsiveContainer>
    );
  };

  const handleExport = async () => {
    let data = [];
    setIsFetching(true);
    try {
      const formulaIds = formulaFilter ? formulaFilter.map((i) => i.value) : [];
      let exYear =
        currentFilterTab === 1
          ? excludeYear
            ? excludeYear.value
            : null
          : null;
      let prevYear =
        currentFilterTab === 1
          ? previousYear.length > 0
            ? previousYear.map((i) => i.value)
            : null
          : null;
      const response = await getMemberships({
        token,
        status: status.value,
        start: 0,
        limit: 80000,
        sort,
        year: year.value,
        excludeYear: exYear,
        previousYear: prevYear,
        formulaIds,
        source: source ? source.value : null,
        search,
        bdcFilter: bdcFilter ? bdcFilter.value : null,
        addAdhesionList: true,
      });
      data = response.data.data;
    } catch (e) {}

    if (data.length === 0) {
      setIsFetching(false);
      return null;
    }

    const rows = [];
    const columnsWidth = [
      { wch: 25 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 }, //organization
      { wch: 15 }, //Address
      { wch: 15 }, //postalCode
      { wch: 15 }, //city
      { wch: 15 }, //uen
    ];

    const currentYear =
      moment().month() == 11 ? moment().year() + 1 : moment().year();
    const yearsOptions = [currentYear];
    for (let i = currentYear - 1; i >= 2020; i--) {
      yearsOptions.push(i);
    }
    yearsOptions.forEach((i) => {
      columnsWidth.push({ wch: 15 });
    });

    data.forEach((item) => {
      const name = `${item.user.firstName ? item.user.firstName : ""} ${
        item.user.lastName ? item.user.lastName : ""
      }`;
      if (name.length > columnsWidth[0].wch) {
        columnsWidth[0].wch = name.length;
      }
      let formule = [];
      if (item.typeMember === "ASSOCIATE") {
        let tmp = "Associé";
        if (item.parent) {
          tmp +=
            " lié à : " +
            item.parent.user.firstName +
            " " +
            item.parent.user.lastName;
        }
        formule.push(tmp);
      } else {
        formule = item.data.map(
          (i) =>
            memberships[i.membersShip].name + " " + formulas[i.formula].name
        );
      }

      const row = {
        Formule: formule.join(" | "),
        "Prénom / Nom": name,
        Email: item.user.mainEmail ?? "",
        Téléphone: item.user.mainPhone
          ? formatPhoneNumberIntl(item.user.mainPhone)
          : "",
        Statut: _(item.status.toLowerCase()) + " " + item.year,
        Date: moment(item.createdAt).format("DD/MM/YYYY"),
        "BDC envoyé?":
          item.typeMember === "ASSOCIATE"
            ? "-"
            : item.orderSent
            ? "Oui"
            : "Non",
        Organisation: item.billingOrganization,
        Adresse: item.billingStreet,
        "Code postal": item.billingPostalCode,
        Ville: item.billingCountry,
        "Num TVA": item.billingCompanyNumber,
      };

      let tmpYears = {};
      yearsOptions.forEach((i) => {
        tmpYears = {
          ...tmpYears,
          [" " + i]: item?.purchasedYears?.includes(i) ? "Oui" : "Non",
        };
      });
      rows.push({ ...row, ...tmpYears });

      if (row.Email.length > columnsWidth[2].wch) {
        columnsWidth[2].wch = row.Email.length;
      }
      if (row["Téléphone"].length > columnsWidth[3].wch) {
        columnsWidth[3].wch = row["Téléphone"].length;
      }
    });

    const worksheet = utils.json_to_sheet(rows);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Dates");

    /* fix headers */
    utils.sheet_add_aoa(
      worksheet,
      [["Formule", "Prénom / Nom", "Email", "Téléphone", "Statut", "Date"]],
      { origin: "A1" }
    );

    /* calculate column width */
    worksheet["!cols"] = columnsWidth;

    /* create an XLSX file and try to save to Presidents.xlsx */
    writeFile(workbook, "Adhesions.xlsx");
    setIsFetching(false);
  };

  const handleCopyAutoLog = async (item) => {
    let ttpLink = `${TTP_OFFFCOURSE_URL}/autolog?idApi2=${
      item.user.id
    }&check=${md5(item.user.id + "FFFCONTROL")}`;
    try {
      await navigator.clipboard?.writeText(ttpLink);
      toast.success("Autolog url copied");
    } catch (error) {
      console.error("Unable to copy to clipboard:", error);
    }
  };

  return (
    <div className="grid-container">
      {(isFetching || isSaving) && <div className="lmask"></div>}
      <div className={styles.listContainer}>
        <h3 className={styles.pageTitle}>
          Liste des adhésions OECCBB{" "}
          {!isLoading &&
            currentFilterTab === 0 &&
            (status.value === "PAID" && !source && !formulaFilter
              ? total > 0 && <span>({total})</span>
              : data?.nbResult > 0 && <span>({data.nbResult})</span>)}
          {!isLoading && currentFilterTab === 1 && data?.nbResult > 0 && (
            <span>({data.nbResult})</span>
          )}
          {!isLoading && currentFilterTab === 2 && total > 0 && (
            <span>({total})</span>
          )}
        </h3>
        <div className={styles.inputSearch}>
          <span className={styles.inputSearch_icon}>
            <i className="icon-ttp-magnifier"></i>
          </span>
          <input
            type="text"
            placeholder="Recherche ..."
            onChange={(e) => handleSearchChange(e)}
          />
        </div>
      </div>

      <ul className={styles.tabForms}>
        <li
          onClick={() => {
            props.setSideBar("MEMBERSHIP_FORM");
          }}
          className={props.sideBar === "MEMBERSHIP_FORM" ? styles.active : ""}
        >
          Ajout Manuel
        </li>
      </ul>
      <div className={styles.topBar}>
        <div style={{ flex: "1" }}>
          <Tabs theme="wtheme" onChangeTab={handleTabFilterChange}>
            <div key={`filter`} label={"Filtrer"}>
              <div className={styles.filterBar}>
                <div className={styles.filter}>
                  <label>Année</label>
                  <Select
                    styles={SELECT_STYLES}
                    options={yearsOptions}
                    isSearchable={false}
                    value={year}
                    onChange={(e) => setYear(e)}
                    selectStyles={selectStyles}
                  />
                </div>
                <div className={styles.filter}>
                  <label>Status</label>
                  <Select
                    styles={SELECT_STYLES}
                    options={statusOptions}
                    isSearchable={false}
                    value={status}
                    onChange={handleStatusChange}
                    selectStyles={selectStyles}
                    classNamePrefix="react-select"
                  />
                </div>
                <div className={styles.filter}>
                  <label>Source</label>
                  <Select
                    isClearable
                    styles={SELECT_STYLES}
                    options={sourceOptions}
                    isSearchable={false}
                    value={source}
                    onChange={(e) => {
                      setCurrentPage(1);
                      setSource(e);
                    }}
                    selectStyles={selectStyles}
                    classNamePrefix="react-select"
                  />
                </div>
                <div className={styles.filter}>
                  <label>Formule</label>
                  <div className={styles.formulaFilter}>
                    <Select
                      isMulti
                      styles={SELECT_STYLES}
                      options={formulaOptions}
                      isSearchable={false}
                      value={formulaFilter}
                      onChange={(e) => {
                        if (e.length > 1) {
                          const associateFormula = e.filter(
                            (i) => i.value === "ASSOCIATE"
                          );
                          if (associateFormula.length > 0) {
                            setFormulaFilter(
                              e.filter((i) => i.value !== "ASSOCIATE")
                            );
                          } else {
                            setFormulaFilter(e);
                          }
                        } else {
                          setFormulaFilter(e);
                        }
                        setCurrentPage(1);
                      }}
                      selectStyles={selectStyles}
                      classNamePrefix="react-select"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div key={`compare`} label={"Comparer"}>
              <p className={styles.fitlerTxt}>
                Afficher les adhérents de l'année 1 qui ne sont pas dans l'année
                2
              </p>

              <div className={styles.compare}>
                <div className={styles.filter}>
                  <label>Année 1</label>
                  <Select
                    isMulti
                    styles={SELECT_STYLES}
                    options={yearsOptions}
                    isSearchable={false}
                    value={previousYear}
                    onChange={(e) => setPreviousYear(e)}
                    isOptionDisabled={(option) =>
                      option.value === excludeYear?.value
                    }
                  />
                </div>
                <div className={styles.filter}>
                  <label>Année 2</label>
                  <Select
                    isClearable
                    styles={SELECT_STYLES}
                    options={yearsOptions}
                    isSearchable={false}
                    value={excludeYear}
                    onChange={(e) => setExcludeYear(e)}
                    isOptionDisabled={(option) =>
                      previousYear?.filter((i) => i.value === option.value) > 0
                    }
                    classNamePrefix="react-select"
                  />
                </div>

                {currentFilterTab === 1 && (
                  <>
                    <div className={styles.filter}>
                      <label>Filtrer BDC</label>
                      <Select
                        isClearable
                        styles={SELECT_STYLES}
                        options={[
                          {
                            value: "NOT_OPEN_EMAIL",
                            label: "Email non ouvert",
                          },
                          { value: "OPEN_EMAIL", label: "Email ouvert" },
                          { value: "CLICK_EMAIL", label: "Email cliqué" },
                        ]}
                        isSearchable={false}
                        value={bdcFilter}
                        onChange={(e) => setBdcFilter(e)}
                        classNamePrefix="react-select"
                      />
                    </div>
                    <div
                      className={styles.filter}
                      style={{ marginTop: "19px" }}
                    >
                      <Button
                        onClick={handleExport}
                        size="sm"
                        disabled={
                          isLoading || !data || data?.data?.length === 0
                        }
                      >
                        <i className="icon-ttp-download" /> Exporter
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div key={`prospect`} label={"Prospecter"}>
              <OeccbbHotels />
            </div>
          </Tabs>
        </div>

        {currentFilterTab !== 2 && (
          <div className={styles.chartContainer}>
            <div className={styles.box}>{renderChart()}</div>
          </div>
        )}
      </div>

      {currentFilterTab === 1 && (
        <CompareList
          isLoading={isLoading}
          data={data}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          memberships={memberships}
          formulas={formulas}
          formulaOptions={formulaOptions}
          refreshList={() => queryClient.invalidateQueries("listMemberships")}
          filters={{
            status,
            source,
            formulaFilter,
            excludeYear,
            previousYear,
            search,
            year,
          }}
        />
      )}
      {currentFilterTab === 0 && (
        <>
          <div className={styles.tableContainer}>
            <table className={`${styles.table} unstriped`}>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th width="40%" className={styles.nowrap}>
                    <span>Formule</span>
                  </th>
                  <th width="20%">Utilisateur</th>
                  <th width="20%">Email</th>
                  <th>Téléphone</th>
                  <th>Statut</th>
                  <th>Date</th>
                  <th></th>
                  {/* {currentFilterTab === 1 && <th></th>} */}
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan={8} className={styles.centerCell}>
                      <Loader
                        style={{
                          height: "10px",
                        }}
                        color={"#B2BCC6"}
                      />
                    </td>
                  </tr>
                ) : data?.data && data.data.length > 0 ? (
                  data.data.map((item) => {
                    let orderTooltip = [
                      <h3 className={styles.toolTitle}>
                        Bon de commande envoyé
                      </h3>,
                    ];
                    if (item.orderSent) {
                      let lines = [];
                      item.orderData.forEach((row) =>
                        lines.push(
                          <li>
                            {memberships[row.membersShip].name +
                              " " +
                              formulas[row.formula].name}
                          </li>
                        )
                      );
                      orderTooltip.push(
                        <ul className={styles.toolLines}>{lines}</ul>
                      );
                    }
                    let membersTooltip = [];
                    if (currentFilterTab === 0 && item.associates.length > 0) {
                      membersTooltip.push(
                        <h3 className={styles.toolTitle}>Membres associés</h3>
                      );
                      let lines = [];
                      item.associates.forEach((row) =>
                        lines.push(
                          <li>{row.firstName + " " + row.lastName}</li>
                        )
                      );
                      membersTooltip.push(
                        <ul className={styles.toolLines}>{lines}</ul>
                      );
                    }
                    let associateTooltip = [];
                    if (item.typeMember === "ASSOCIATE" && item.parent) {
                      associateTooltip.push(
                        <h3 className={styles.toolTitle}>
                          Lié à :{" "}
                          {item.parent.user.firstName +
                            " " +
                            item.parent.user.lastName}
                        </h3>
                      );
                    }
                    return (
                      <tr key={item.id}>
                        <td>
                          <span
                            onClick={() => handleCopyAutoLog(item)}
                            title="Autolog url"
                            className={styles.copyIcon}
                          >
                            <i className="icon-ttp-copy" />
                          </span>
                        </td>
                        <td>
                          {item.typeMember === "ASSOCIATE" ? (
                            <Tooltip title={associateTooltip} arrow>
                              Associé
                            </Tooltip>
                          ) : (
                            item.data.map((row) => (
                              <p className={styles.btmBorder}>
                                {memberships[row.membersShip].name +
                                  " " +
                                  formulas[row.formula].name}
                              </p>
                            ))
                          )}
                        </td>
                        {/* <td>
                    {item.data.map((row) => (
                      <p className={styles.btmBorder}>
                        {formulas[row.formula].name}
                      </p>
                    ))}
                  </td> */}
                        <td>
                          {item.user.firstName} {item.user.lastName}
                        </td>
                        <td>{item.user.mainEmail}</td>
                        <td className={styles.nowrap}>
                          {item.user.mainPhone
                            ? formatPhoneNumberIntl(item.user.mainPhone)
                            : "-"}
                        </td>
                        <td>
                          {_(item.status.toLowerCase())}
                          <br />
                          {item.year}
                        </td>
                        <td>{moment(item.createdAt).format("DD/MM/YYYY")}</td>
                        {currentFilterTab === 0 && (
                          <td>
                            {item.associates.length > 0 ? (
                              <Tooltip title={membersTooltip} arrow>
                                <span className={styles.persons}>
                                  <IconUsers />{" "}
                                  <span>{item.associates.length}</span>
                                </span>
                              </Tooltip>
                            ) : (
                              <span className={styles.disablePerson}>
                                <IconUsers />
                              </span>
                            )}
                          </td>
                        )}
                        {currentFilterTab === 1 && (
                          <td>
                            {item.orderSent ? (
                              <Tooltip title={orderTooltip} arrow>
                                <span className={styles.successBtn}>
                                  <i className="icon-ttp-check" />
                                </span>
                              </Tooltip>
                            ) : // <Button
                            //   // onClick={() => handleSendOrder(item)}
                            //   title="Bon de commande envoyé"
                            //   variant="send"
                            // >
                            //   <i className="icon-ttp-check" />
                            // </Button>

                            item.showBC ? (
                              <Button
                                onClick={() => handleSendOrder(item)}
                                title="Envoyer le bon de commande"
                              >
                                <i className="icon-ttp-plane" />
                              </Button>
                            ) : (
                              <></>
                            )}
                          </td>
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6} className={styles.centerCell}>
                      Liste vide (aucune donnée ne répond aux filtres)
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {data?.nbResult && (
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={data.nbResult}
              pageSize={LIMIT}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )}
        </>
      )}

      {/* <MemberOrderModal
        isOpen={showBillingModal}
        closeModal={() => setShowBillingModal(false)}
        currentMemberOrder={currentMemberOrder}
        onSave={handleMemberShipOrder}
        formulaOptions={formulaOptions}
        SELECT_STYLES={SELECT_STYLES}
      /> */}
    </div>
  );
};

export default ManageMembership;
